<template>
    <div class="body">
        <div class="suzhou_image">
            <img src="@/assets/images/official/suzhou.jpg" />
        </div>
        <ul>
            <li v-for="(item, index) in data.items" :key="index">
                <div :style="{
                    backgroundImage: 'url(' + item.cover + ')',
                    backgroundSize: '100% 100%',
                    backgroundPosition: '0% 0%'
                }" class="card">
                    <div class="content">
                        <div class="title">
                            <img src="@/assets/images/official/star.png" height="100%" style="margin-top: 5px;" />
                            <div class="name">
                                {{ item.title }}
                            </div>
                        </div>
                        <div class="info" v-if="item.features[0]">
                            <img src="@/assets/images/official/phone.png" height="100%"
                                style="margin-top: 5px;margin-left: 5px;" />
                            <div class="text">
                                {{ item.features[0].feature }}
                            </div>
                        </div>
                        <div class="info" v-if="item.features[1]">
                            <img src="@/assets/images/official/address.png" height="100%"
                                style="margin-top: 5px;margin-left: 5px;" />
                            <div class="text">
                                {{ item.features[1].feature }}
                            </div>
                        </div>
                    </div>
                    <div v-if="item.buttons.length">
                        <div v-for="(button, index) in item.buttons" :key="index" class="button"
                            @click="toUrl(button.router)">
                            <span style="display: flex;">
                                <i class="line"></i>{{ button.name }}</span>
                        </div>
                    </div>
                </div>
            </li>
            <div class="clear"></div>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
    data() {
        return {};
    },
    methods: {
        toUrl(url) {
            if (url.includes('http')) {
                window.open(url);
            } else {
                this.$router.push(url);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}

.body {
    overflow: hidden;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px 0;
}

.body ul {
    width: 100%;
    margin-left: 1%;
}

.body li {
    width: 32%;
    float: left;
    margin-right: 1%;
}

.card {
    height: 380px;
    background-size: 100% 100%;
    padding-top: 76px;
    margin-bottom: 10px;
    border-radius: 5px;
    position: relative;

    .button {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        width: 100%;
        height: 45px;
        line-height: 45px;
        font-size: 16px;
        color: #333;
        padding-left: 20px;
        margin-top: 12px;
        cursor: pointer;

        .line {
            width: 50px;
            border-bottom: 1px solid;
            height: 25px;
        }

        &:hover {
            color: #467ed1;
        }
    }
}

.suzhou_image {
    width: 98%;
    margin: 0 auto 14px auto;
}

.suzhou_image img {
    width: 100%;
    border-radius: 10px;
    margin-top: 66px;
}

.content {
    height: 60%;
}

.title {
    width: 94%;
    margin: 0 auto 25px auto;
    display: flex;

    .name {
        font-size: 30px;
        margin-left: 20px;
        color: #666;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
}

.info {
    width: 94%;
    margin: 20px auto 0 auto;
    display: flex;

    .text {
        font-size: 16px;
        margin-left: 25px;
        line-height: 32px;
        color: #666;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
}

@media screen and (max-width: 700px) {
    .body {
        width: 96%;
    }

    .body li {
        width: 98%;
    }

    .suzhou_image img {
        margin-top: 30px;
    }
}</style>
